@font-face {
  font-family: 'FaroWeb-BoldLucky';
  src: url('fonts/FaroWeb-BoldLucky.otf');
}

@font-face {
  font-family: 'FaroWeb-RegularLucky';
  src: url('fonts/FaroWeb-RegularLucky.otf');
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('fonts/gt-walsheim-bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('fonts/gt-walsheim-medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('fonts/gt-walsheim-regular.ttf') format('truetype');
  font-weight: 400;
}

body,
#root {
  margin: 0;
  background-color: #f1f0f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'FaroWeb-RegularLucky';
}
